import React, { useEffect } from "react";

const Content = () => {
  const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }

  useEffect(() => {
    addScript("https://app.termly.io/embed-policy.min.js")
  }, [])

  return (
    <>
      <div className="content-section bg-default-6 pt-lg-15 pt-10 pb-13 pb-lg-21">
        <div name="termly-embed" data-id="fd629e53-f0c5-444b-9146-f219c41a7807" data-type="iframe"></div>
      </div>
    </>
  );
};

export default Content;