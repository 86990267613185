import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/privacy-policy/Content";

const PrivacyPolicy = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Content />
      </PageWrapper>
    </>
  );
};

export default PrivacyPolicy;